<app-header></app-header>
<div class="vital-education-container">
  <div class="header-section">
    <div class="header-content">
      <h1>Vital Education</h1>
      <h3>Assurez l'avenir de vos enfants</h3>
      <p>
        Assurez l'avenir de vos enfants. Pour vous aider à financer une partie ou l'intégralité des études de
        vos enfants ou de leur constituer un capital qui leur permettra de
        démarrer confortablement leur vie active, La Marocaine Vie vous propose
        Vital Education, une assurance souple et sur mesure disponible
        exclusivement chez Société Générale.
      </p>
    </div>
    <img src="/assets/images/la_marocaine_vie_cover 1 (2).png" alt="Header Image">
  </div>
  <div class="form-container" style="gap: 20px;">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <h2 style="color:  rgba(230, 0, 40, 1);">Simulez votre épargne en 1 mn</h2>
      <div class="form-row">
        <div class="form-group">
          <label for="age">Age</label>
          <input id="age" formControlName="age" type="number">
        </div>
        <div class="form-group">
          <label for="epargne">Epargne</label>
          <input id="epargne" formControlName="epargne" type="number">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="nombre">Nombre</label>
          <input id="nombre" formControlName="nombre" type="number">
        </div>
        <div class="form-group">
          <label for="montant">Montant</label>
          <input id="montant" formControlName="montant" type="number">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="durée">Durée</label>
          <input id="durée" formControlName="durée" type="number">
        </div>
        <div class="form-group">
          <label for="taux">Taux</label>
          <input id="taux" formControlName="taux" type="number">
        </div>
      </div>
      <div class="form-group">
        <label for="nombre">Nombre</label>
        <input id="nombre" formControlName="nombre" type="number">
      </div>
      <div class="slider">
        <label for="monthlyPayment">Montant versement mensuel *</label>
        <div class="slider-dev">
          <div class="slider-container">
            <input type="range" id="range" [value]="rangeValue" (input)="setValue($event)" min="200" max="10000"
              step="100">
            <output id="rangeV" class="bubble"></output>
          </div>
          <div class="range-labels">
            <span>200</span>
            <span>5 000</span>
            <span>10 000</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Avez-vous un numéro de contrat Marocaine Vie ?</label>
        <div class="d-flex" style="gap: 30px;">
          <div>
          <input type="radio" formControlName="contract" value="yes"> Oui
        </div>
        <div>
          <input type="radio" formControlName="contract" value="no"> Non
        </div>
        </div>
      </div>
      <div class="form-group">
        <input type="checkbox" formControlName="terms"> J'ai lu et j'accepte les mentions légales et les conditions
        générales d'utilisation.
      </div>
      <button class="rounded-pill" type="submit">Calculer</button>
    </form>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button underline-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
            aria-expanded="true" aria-controls="collapseOne" (click)="toggleGuarantees()">
            Garanties
          </button>
          <div *ngIf="showGuarantees" class="red-line"></div>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul>
              <li>
              Vital Education vous permet au terme de la période du contrat, le versement de l’épargne constituée.
            </li>
            <li>
              En cas de décès avant le terme du contrat, l’épargne constituée est versée à votre enfant.</li>
              <li>
              En cas de décès d’Invalidité Absolue et Définitive (IAD) avant 65 ans, La Marocaine Vie prend en charge
              les versements programmés entre la date du décès ou de l’IAD et celle du 18ème anniversaire de l’enfant
              bénéficiaire.
            </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" (click)="toggleAdvantages()">
            Avantages
          </button>
          <div *ngIf="showAdvantages" class="red-line"></div>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p>
              Vital Education vous offre des avantages fiscaux sur les versements effectués.<br>
              Une gestion financière adaptée à votre profil d'investisseur.<br>
              Une flexibilité des versements pour s’adapter à votre situation financière.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="app-help-section-container">
      <div class="help-section">
        <app-help-section></app-help-section>
      </div>
    </div>
  </div>
  <app-pre-footer></app-pre-footer>
  <app-footer></app-footer>
