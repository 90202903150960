import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { HelpSectionComponent } from '../help-section/help-section.component';
import { PreFooterComponent } from '../pre-footer/pre-footer.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-grid-product',
  standalone: true,
  imports: [ReactiveFormsModule,CommonModule, FormsModule,HeaderComponent, HelpSectionComponent, PreFooterComponent, FooterComponent],
  templateUrl: './grid-product.component.html',
  styleUrl: './grid-product.component.css',
})
export class GridProductComponent {
  form: FormGroup;
  showGuarantees = false;
  showAdvantages = false;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.form = this.fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      age: ['', Validators.required],
      childAge: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      profession: [''],
      amount: [200, Validators.required],
      contract: [''],
      terms: [false, Validators.requiredTrue],
      epargne: [''],
      nombre: [''],
    });
  }

  // setStaticData(): void {
  //   this.form.patchValue({
  //     lastName: 'KADDAH',
  //     firstName: 'Samir',
  //     age: 40,
  //     childAge: 12,
  //     phone: '06 61 01 02 03',
  //     email: 'samirkaddah@gmail.com',
  //     profession: 'Chef de production',
  //     amount: 3700,
  //     contract: 'no'
  //   });
  // }
  onSubmit() {
    if (this.form.valid) {
      console.log('Form Submitted', this.form.value);
    } else {
      console.error('Form is invalid');
    }
  }
  toggleGuarantees() {
    console.log("guarantees clicked");
    this.showGuarantees = !this.showGuarantees;
  }

  toggleAdvantages() {
    console.log("advantages clicked");

    this.showAdvantages = !this.showAdvantages;
  }


  //-----------------Range Slider code ---------------------------------------

  rangeValue: number = 5000;

  ngOnInit() {
    this.updateValuePosition()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateValuePosition();
    });
  }

  updateValuePosition() {
    const range = document.getElementById('range') as HTMLInputElement;
    const rangeV = document.getElementById('rangeV') as HTMLElement;

    if (!range || !rangeV) {
      console.error('Range or RangeV element not found');
      return;
    }

    // Calculate percentage for the gradient
    const maxValue = Number(range.max);
    const minValue = Number(range.min);
    const value = this.rangeValue;

    const newValue = ((value - minValue) * 100) / (maxValue - minValue);
    const newPosition = 10 - newValue * 0.2;

    rangeV.innerHTML = `${this.rangeValue} DH`;
    rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;

    range.style.background = `linear-gradient(to right, #d32d33 ${newValue}%, #ddd ${newValue}%)`;
  }

  setValue(event: Event) {
    this.rangeValue = (event.target as HTMLInputElement).valueAsNumber;
    this.updateValuePosition();
  }
}
