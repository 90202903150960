import { Component } from '@angular/core';

@Component({
  selector: 'no-content',
  styleUrls: ['./no-content.css'],
  template: `
    <div class="fix-width">
      <!-- <header-page #header></header-page> -->
      <div class="content main">
        <div id="e-404">
          <p id="illustration-404">
            <img src="../../assets/img/e_404.png" alt="404" />
          </p>
          <h1>Oops, la page que vous recherchez n’existe pas!</h1>
          <h4>Your may have mistyped the address or the page may have moved</h4>
          <a href="/particulier" class="btn">Retour à l'accueil</a>
        </div>
        <!-- <footer-white [header]="header"></footer-white> -->
      </div>
    </div>
  `,
})
export class NoContentComponent {}
