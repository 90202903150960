import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet, RouterModule } from '@angular/router';
import { GlobalService } from '../../legacy/providers/global.service';
import { Article } from '../../models/article.model';
import { HeaderComponent } from "../header/header.component";
import { PreFooterComponent } from "../pre-footer/pre-footer.component";
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-actualites',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, PreFooterComponent,FooterComponent],
  templateUrl: './actualites.component.html',
  styleUrls: ['./actualites.component.css']
})
export class ActualitesComponent implements OnInit {
  featuredArticle: Article | undefined;
  allArticles: Article[] = [];
  displayedArticles: Article[] = [];
  articlesPerPage = 6;
  currentPage = 0;
  totalArticlesFetched = 0;
  hasMoreArticles = true;
  isLoading = false;
  isLoadingFeatured = false;
isLoadingArticles = false;
isLoadingMore = false;



  constructor(
    private globalService: GlobalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadFeaturedArticle();
    this.loadInitialArticles();
  }

  loadFeaturedArticle(): void {
    this.isLoadingFeatured = true;
    this.globalService.getFeaturedArticle().subscribe((response: any) => {
      console.log('Featured Article Response:', response);
      this.isLoadingFeatured = false;
      if (response.success) {
        this.featuredArticle = response.data;
      }
    });
  }

  loadInitialArticles(): void {
    this.isLoadingArticles = true;
    const timestamp = new Date().getTime();
    this.globalService.getNewsList(this.currentPage, this.articlesPerPage).subscribe((response: any) => {
      console.log('Initial API Response:', response);
      this.isLoadingArticles = false;
      if (response.success) {
        const fetchedArticles = response.data;
       if (this.featuredArticle) {
          this.displayedArticles = [this.featuredArticle, ...fetchedArticles];
        } else {
          this.displayedArticles = fetchedArticles;
        }
  
        this.totalArticlesFetched = response.count;
        this.hasMoreArticles = fetchedArticles.length === this.articlesPerPage;
        console.log('Initial Articles:', this.displayedArticles);
        console.log('Total Articles Fetched:', this.totalArticlesFetched);
      } else {
        console.error('Failed to load initial articles:', response);
      }
    });
  }
  
  loadMoreArticles(): void {
    if (this.hasMoreArticles) {
      this.isLoadingMore = true;
      this.currentPage++;
      this.globalService.getNewsList(this.currentPage, this.articlesPerPage).subscribe((response: any) => {
        console.log('Load more response:', response);
        this.isLoadingMore = false;
        if (response.success) {
          this.displayedArticles = [...this.displayedArticles, ...response.data];
          this.totalArticlesFetched += response.count;
          this.hasMoreArticles = response.data.length === this.articlesPerPage;
          console.log('Articles after loading more:', this.displayedArticles);
        } else {
          console.error('Failed to load more articles:', response);
        }
      });
    } else {
      console.log('No more articles to load.');
    }
  }


  // goToDetails(articleId: number): void {
  //   this.router.navigate(['/news-details', articleId]);
  // }

  slugify(str:string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
    str = str.toLowerCase(); // convert string to lowercase
    str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
             .replace(/\s+/g, '-') // replace spaces with hyphens
             .replace(/-+/g, '-'); // remove consecutive hyphens
    return str.substring(0, 32); // on ne retourne que les 32 premiers caractères
  }
}
