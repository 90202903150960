<app-header></app-header>
<div class="container-actualites" *ngIf="!isLoadingFeatured && !isLoadingArticles">
  <div class="actualites-body">
    <div class="headline">
      <h2 class="grid-artcile-titre  mb-3">Actualités à la une</h2>

      <div class="featured-article" *ngIf="featuredArticle">
        <a [href]="['/actualites/' + slugify(featuredArticle.title) + '/'+ featuredArticle.id]" class="featured-article-link">
          <div class="featured-image" style="background-image: url({{featuredArticle.image}})" >
          </div>
          <!-- <img class="" src="/assets/images/la_marocaine_vie_cover 1 (3).png" alt="Featured Article Image"> -->
          <div class="featured-article-text">
            <div class="article-info">
              <p class="featured-article-date">{{ featuredArticle.date | date: 'dd MMM yyyy' }}</p>
              <h3 class="article-title featured-color">{{ featuredArticle.title }}</h3>
              <div class="tags">
                <span class="featured-tag" *ngFor="let tag of featuredArticle.tags">{{ tag.name }}</span>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="articles">
      <h2 class="grid-artcile-titre-all">Tous nos articles</h2>
      <div class="article-grid">
        <div class="article-card" *ngFor="let article of displayedArticles">
          <a class="link-article" [href]="['/actualites/' + slugify(article.title) + '/'+ article.id]" class="featured-article-link">
          <!-- <img [src]="article.image" alt="Article Image" class="article-image"> -->
           <div class="article-image" style="background-image: url({{article.image}});" ></div>
          <div class="article-info">
            <p class="article-date">{{ article.date | date: 'dd MMM yyyy' }}</p>
            <div class="tags">
              <span class="tag" *ngFor="let tag of article.tags">{{ tag.name }}</span>
            </div>
            <h3 class="article-title">{{ article.title }}</h3>
          </div>
          </a>
        </div>
      </div>
    </div>
     <!-- Loader and message while fetching articles -->
     <!-- <div class="loader" *ngIf="isLoading">
      <div class="loader-spinner"></div>
      <p>Chargement en cours...</p>
    </div> -->

<button
  class="load-more"
  *ngIf="hasMoreArticles"
  (click)="loadMoreArticles()">
  Afficher plus d'actualités <img src="/assets/images/Vector (7).svg" alt="Load more icon" loading="lazy">
</button>

<!-- <p *ngIf="!hasMoreArticles && displayedArticles.length > 0">Tous les articles sont chargés.</p> -->
  </div>
</div>
<app-pre-footer></app-pre-footer>
<app-footer></app-footer>
